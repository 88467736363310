import { createGlobalStyle } from 'styled-components'

  const screenSize = {
    mobile: 320,
    mobileLandscape: 480,
    tablet: 576,
    tabletHoriz: 600,
    smallDesktop: 768,
    desktop: 992,
    tabletLandscape: 1024,
    desktopPlus: 1200,
    lutrahddesktop: 1600,
    desktopWide: 1920,
    // see devices

    // @media only screen and (min-width: 992px){
    //   @content;
    // }
  }


export const Theme = {
  /* ======================
          colors
  ========================*/
  
  color: {
    dark: '#21212a', // #21212a
    gray: '#a1a1a1',
    highlight: '#fea04f',
    
    title : '#000',
    
    // // links
    // link : 'red',
    // linkHover : 'blue'
  },

  // screen sizes
    wide: {
      mobile: screenSize.mobile + 'px',
      mobileLandscape: screenSize.mobileLandscape + 'px',
      tablet: screenSize.tablet + 'px',
      tabletHoriz: screenSize.tabletHoriz + 'px',
      smallDesktop: `${screenSize.smallDesktop}px`,
      desktop: screenSize.desktop + 'px',
      tabletLandscape: screenSize.tabletLandscape + 'px',
      desktopPlus: screenSize.desktopPlus + 'px',
      lutrahddesktop: screenSize.lutrahddesktop + 'px',
      desktopWide: screenSize.desktopWide + 'px',
    }
    

}
    /* ======================
          devices
          https://jsramblings.com/2018/02/04/styled-components-media-queries.html

          @media ${minWidth.desktop} {
            width:65%;
          } 
  ========================*/
export const minWidth = Object.keys(screenSize).reduce((acc, cur) => {
  acc[cur] = `(min-width: ${screenSize[cur]}px)`;
  return acc;
  }, {});

export const maxWidth = Object.keys(screenSize).reduce((acc, cur) => {
  acc[cur] = `(max-width: ${screenSize[cur]}px)`;
  return acc;
  }, {});


// global styles

export const GlobalStyle = createGlobalStyle`

  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 22px; 
    box-sizing: border-box;
    line-height: 1.6;
  }

  body {
    margin: 0;
    padding:0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    text-rendering: optimizeLegibility;
    background: ${props => props.theme.color.dark};
    color: ${props => props.theme.color.gray};
  }

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  a:active,
  a:hover {
    outline-width: 0;
  }

  p *:last-child {
    margin-bottom: 0;
  }
  
`

