// https://noahgilmore.com/blog/easy-gatsby-image-components/
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const SpeedImage = ( { src, alt } ) => ( // _withWebp_tracedSVG
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1900,  quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(src);
      });
      if (!image) { return null; }
      
      const imageSizes = image.node.childImageSharp.fluid;
      return (
        <Img
          alt={alt}
          fluid={imageSizes}
        />
      );
    }}
  />
)

export default SpeedImage