import React from 'react'
import styled from 'styled-components'



const Main = ( {children} ) => 

<MainStyled> {children} </MainStyled>

export default Main

// styles

const MainStyled = styled.main`
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
`
